<script lang="ts" setup>
import * as THREE from 'three'
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js'

// Define these props with defaults
const props = defineProps({
  amplitudeMin: {
    type: Number,
    default: 1,
  },
  amplitudeMax: {
    type: Number,
    default: 5,
  },
  turbulence: {
    type: Number,
    default: 0.05,
  },
  camera: {
    type: Object,
    default: () => ({
      x: 0,
      y: 8,
      z: -2,
    }),
  },
  speed: {
    type: Number,
    default: 10,
  },
})

const sceneEl = ref<HTMLElement | null>(null)
const width = ref(0)
const height = ref(0)

const animationFrame = ref(0)

let camera: THREE.PerspectiveCamera | undefined
let scene: THREE.Scene | undefined
let renderer: THREE.WebGLRenderer | undefined
let composer: EffectComposer | undefined
let centerRing: THREE.Line | null = null

const lines: THREE.Line[] = []

function onWindowResize() {
  if (!camera || !renderer || !composer)
    return

  camera.aspect = width.value / height.value
  camera.updateProjectionMatrix()

  renderer.setSize(width.value, height.value)
  composer.setSize(width.value, height.value)
}

const clock = new THREE.Clock()

function animate() {
  if (!scene || !camera || !renderer || !composer || centerRing === null)
    return

  requestAnimationFrame(animate)
  const time = clock.getElapsedTime()
  const amplitude = props.amplitudeMin + (props.amplitudeMax - props.amplitudeMin) / 2

  lines.forEach((line, index) => {
    line.position.z = Math.sin((time * props.speed - index) * props.turbulence) * amplitude
  })

  // camera.lookAt(centerRing.position)

  composer.render()
}

function makeRing(scene: THREE.Scene, size: number) {
  const g = new THREE.BufferGeometry().setFromPoints(
    new THREE.Path().absarc(0, 0, (5 * size * 0.1), 0, Math.PI * 2).getSpacedPoints(128),
  )
  const m = new THREE.LineBasicMaterial({ color: 'white' })
  const line = new THREE.Line(g, m)

  line.position.set(0, 0, 0)
  return line
}

onMounted(() => {
  if (!sceneEl.value)
    return

  width.value = sceneEl.value.clientWidth
  height.value = sceneEl.value.clientHeight

  scene = new THREE.Scene()

  camera = new THREE.PerspectiveCamera(70, width.value / height.value, 1, 1000)
  camera.position.set(props.camera.x, props.camera.y, props.camera.z)
  // Rotate the camera
  camera.rotation.x = THREE.MathUtils.degToRad(-120)
  camera.rotation.y = THREE.MathUtils.degToRad(0)
  camera.rotation.z = THREE.MathUtils.degToRad(0)

  // Add Rings
  for (let i = 0; i < 20; i++) {
    const ring = makeRing(scene, i * 0.5)
    if (i === 0)
      centerRing = ring

    lines.push(ring)
  }

  lines.forEach((line) => {
    if (scene)
      scene.add(line)
  })

  const ambientLight = new THREE.AmbientLight(0xE7E7E7)
  scene.add(ambientLight)

  const pointLight = new THREE.PointLight(0xFFFFFF, 500)
  camera.add(pointLight)
  scene.add(camera)

  renderer = new THREE.WebGLRenderer({
    alpha: true,
  })

  renderer.setPixelRatio(window.devicePixelRatio)
  renderer.setSize(width.value, height.value)
  sceneEl.value.appendChild(renderer.domElement)

  // postprocessing
  composer = new EffectComposer(renderer)
  const renderPass = new RenderPass(scene, camera)
  composer.addPass(renderPass)

  // const controls = new OrbitControls(camera, renderer.domElement)
  // controls.addEventListener('change', () => {
  //   console.log(controls.object.position)
  // })

  window.addEventListener('resize', onWindowResize)

  animate()
})

onUnmounted(() => {
  // clear out the scene
  sceneEl.value?.removeChild(renderer?.domElement)
  cancelAnimationFrame(animationFrame.value)
})
</script>

<template>
  <div ref="sceneEl" />
</template>
