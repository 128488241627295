<script setup lang="ts">
import { defineLayout } from '#imports'

export interface MenuItem {
  title: string
  to?: string
  icon?: string
  about?: string
  children?: MenuItem[]
}

defineLayout({
  label: 'Voix Marketing',

  grid: {
    default: {
      container: false,
      columns: 6,
      gutter: '2rem',
      gap: '32px', // .gap-8
    },
    lg: {
      container: true,
      columns: 12,
      gutter: '2rem',
      gap: '32px', // .gap-8
    },
  },

  colors: {
    fields: {
      picker: true,
      palette: true,
    },
    background: {
      picker: true,
      palette: true,
    },
    foreground: {
      picker: true,
      palette: true,
    },
  },
  colorPalette: {
    '#FFFD5A': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#3805F5': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#EA3F32': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#4C9F70': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#FF6392': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#BFD7EA': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#22D3EE': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#0a0a0a': {
      fields: true,
      background: true,
      foreground: true,
    },
  },
})

// Generated much of this at https://realfavicongenerator.net
useHead({
  htmlAttrs: {
    lang: 'en-US',
  },
  meta: [
    { name: 'msapplication-TileColor', content: '#000000' },
    { name: 'msapplication-config', content: '/favicons/voix-marketing/browserconfig.xml' },
    { name: 'theme-color', content: '#000000' },
    { property: 'og:title', content: 'Voix: Your Voice in Web Publishing' },
    { property: 'og:description', content: 'Voix is a powerful, customizable web platform that simplifies content management, supports AI, and enables seamless multitenancy for diverse digital projects.' },
    { property: 'og:image', content: '/imgs/og-image-base.jpg' },
    { property: 'og:url', content: 'https://voix.ooo' },
    { property: 'og:type', content: 'website' },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: 'Voix: Your Voice in Web Publishing' },
    { name: 'twitter:description', content: 'Voix is a powerful, customizable web platform that simplifies content management, supports AI, and enables seamless multitenancy for diverse digital projects.' },
    { name: 'twitter:image', content: '/imgs/og-image-base.jpg' },
  ],
  link: [
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/favicons/voix-marketing/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/svg', sizes: '32x32', href: '/favicons/voix-marketing/favicon.svg' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicons/voix-marketing/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicons/voix-marketing/favicon-16x16.png' },
    { rel: 'manifest', href: '/favicons/voix-marketing/site.webmanifest' },
    { rel: 'mask-icon', href: '/favicons/voix-marketing/safari-pinned-tab.svg', color: '#5bbad5' },
    { rel: 'shortcut icon', href: '/favicons/voix-marketing/favicon.ico' },
  ],
})

const menuItems: MenuItem[] = [
  {
    title: 'Home',
    to: '/',
  },
  {
    title: 'Key Features',
    about: 'Looking to learn more about the features of Voix? Well, you\'re in the right place. Here you will find some of the the major features that make Voix such a special experience.',
    children: [
      {
        title: 'Content Management',
        to: '/content-management',
        icon: 'heroicons:home-modern',
        about: 'Effortlessly create, edit, and manage digital content with our user-friendly platform. Discover how our Voix enhances productivity and keeps your content fresh.',
      },
      {
        title: 'Brand Management',
        to: '/brand-management',
        icon: 'heroicons:presentation-chart-line',
        about: 'Maintain consistency across all digital touchpoints while empowering your team with flexible tools to elevate your brand. Learn how Voix makes brand management seamless',
      },
      {
        title: 'AI',
        to: '/artificial-intelligence',
        icon: 'heroicons:bolt',
        about: 'Leverage AI to boost content generation, advanced search, and translation. Explore how our intelligent system enhances your content management experience.',
      },
      {
        title: 'Multitenancy',
        to: '/multitenancy',
        icon: 'heroicons:square-3-stack-3d',
        about: 'Manage multiple websites or applications from a single platform, effortlessly streamlining operations. See how you can scale with ease across your digital properties',
      },
      {
        title: 'Customized User Experience',
        to: '/customized-user-experiences',
        icon: 'heroicons:user',
        about: 'Tailor content to individual users and localize for global regions. Discover how personalized experiences can drive engagement and growth.',
      },
      {
        title: 'Infrastructure & Delivery',
        to: '/infrastructure-delivery',
        icon: 'heroicons:globe-alt',
        about: 'Deliver lightning-fast content with global deployment and optimized infrastructure. Learn how our platform ensures peak performance at every step.',
      },
    ],
  },

  {
    title: 'Service',
    to: '/world-class-service',
    about: '',
  },
  {
    title: 'Custom Applications',
    to: '/custom-applications',
    icon: 'heroicons:cube-transparent',
  },
  {
    title: 'Sales',
    to: '/sales',
  },
]
</script>

<template>
  <div id="app" class="relative min-h-[100vh] bg-neutral-950 text-stone-100 font-serif voix-marketing-theme">
    <div class="absolute inset-0 pointer-events-none z-50">
      <div id="portal-modal" class="pointer-events-auto" />
    </div>
    <div class="relative z-40">
      <div class="relative z-40">
        <VoixNavigation :menu-items="menuItems" />
      </div>
      <slot />
      <VoixFooter :menu-items="menuItems" />
    </div>
  </div>
</template>

<style>
@import url('@/assets/css/voix-main.scss');
</style>
