<script lang="ts" setup>
import type { MenuItem } from '@/layouts/VoixMarketing.vue'
import { inView } from 'motion'

const props = defineProps<{
  menuItems: MenuItem[]
}>()

const filteredMenuItems = computed(() => {
  return props.menuItems.filter(item => item.title !== 'Home')
})

const footerEl = templateRef('footerEl')
const footerInView = ref(false)
onMounted(() => {
  inView(footerEl.value, () => {
    footerInView.value = true

    return () => {
      footerInView.value = false
    }
  })
})
</script>

<template>
  <div ref="footerEl" class="relative py-32">
    <div class="md:border-b border-neutral-800 px-8 lg:px-0">
      <div class="relative max-w-screen-2xl mx-auto font-sans overflow-hidden">
        <div class="relative z-10 lg:max-w-lg pb-12 lg:pl-12 -mt-8">
          <template v-for="section in filteredMenuItems" :key="section.title">
            <NuxtLink v-if="section.to" :to="section.to" class="mt-8 flex justify-between text-sm  border-b border-white/50 pb-2 mb-4 cursor-pointer" :aria-label="`Click to learn more: ${section.title}`">
              <span>{{ section.title }}</span>
              <span class="uppercase tracking-wider text-2xs bg-voix-stroke text-black px-2">Click to learn more</span>
            </NuxtLink>
            <span v-else class="mt-8 flex justify-between text-sm  border-b border-white/50 pb-2 mb-4 ">{{ section.title }}</span>

            <ul class="text-xs space-y-3 lg:text-right">
              <li v-for="item in section.children" :key="item.title">
                <NuxtLink :to="item.to" class="block text-neutral-400 hover:-translate-x-1 duration-300">
                  {{ item.title }}
                </NuxtLink>
              </li>
              <li v-if="section.title === 'Sales'">
                <NuxtLink to="/sales" class="text-neutral-400">
                  Book a Demo
                </NuxtLink>
              </li>
              <li v-if="section.title === 'Sales'">
                <a href="tel:+12293038649" class="text-neutral-400">+1 (229) 303-VOIX (8649)</a>
              </li>
              <li v-if="section.title === 'Sales'">
                <NuxtLink to="mailto:sales@voix.ooo" class="text-neutral-400">
                  sales@voix.ooo
                </NuxtLink>
              </li>
            </ul>
          </template>
        </div>

        <LazyCopyFooterQuotes />

        <NuxtLink to="/" class="relative lg:absolute lg:bottom-8 lg:right-12 z-20" aria-label="Go to Homepage">
          <VoixLogo class="w-24 mx-auto md:mx-0 mt-12 lg:mt-0" mode="both" direction="horizontal" animate-on-hover />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.circles {

  &::before {
    @apply absolute z-10 bottom-0 right-0 bg-gradient-to-l from-neutral-950 from-20% to-neutral-950/0 w-full h-full;
    content: '';
  }
}
</style>
