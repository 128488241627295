<script lang="ts" setup>
import type { MenuItem } from '@/layouts/VoixMarketing.vue'
import { useEventListener } from '@vueuse/core'

defineProps<{
  menuItems: MenuItem[]
}>()

const showMenu = ref(false)
const showingSubMenu = ref(false)
const activeMenuItem: Ref<MenuItem | null> = ref(null)

function activateSubmenu(menuItem: MenuItem | null = null): void {
  if (menuItem)
    activeMenuItem.value = menuItem

  showingSubMenu.value = true
}

function requestDeactivateSubmenu() {
  activeMenuItem.value = null
}

function closeMenu() {
  showMenu.value = false
  showingSubMenu.value = false
  activeMenuItem.value = null
}

const currentSection: Ref<null | MenuItem> = ref(null)

const currentAbout = computed(() => {
  if (currentSection.value && currentSection.value.about) {
    return currentSection.value.about
  }

  if (activeMenuItem.value && activeMenuItem.value.about) {
    return activeMenuItem.value.about
  }
  return null
})

// Calculate if the user has scrolled down past 500 px
const scrolledPast500 = ref(false)

useEventListener(window, 'scroll', () => {
  scrolledPast500.value = window.scrollY > 500
})
</script>

<template>
  <div class="relative font-serif">
    <!-- Main menu -->
    <div class="relative z-60 w-full max-w-screen-2xl mx-auto flex justify-between items-center h-24 px-8">
      <div class="flex items-center space-x-20 w-32">
        <NuxtLink to="/" aria-label="Go to Voix Homepage" @click="closeMenu">
          <VoixLogo class="w-32" mode="both" direction="horizontal" animate-on-hover />
        </NuxtLink>
      </div>
      <div class="-translate-y-1 flex items-center space-x-8  w-32">
        <button class="flex items-center space-x-2" aria-label="Open Menu" @click="showMenu = true">
          <Icon name="heroicons:bars-3-20-solid" class="w-8 h-8" />
          <span class="text-lg">Menu</span>
        </button>
      </div>
    </div>

    <!-- Fixed Main Menu -->
    <div
      class="fixed top-0 left-0 right-0 z-60 duration-500 ease-out bg-neutral-950/50 backdrop-blur-lg" :class="{
        '-translate-y-full': !scrolledPast500,
      }"
    >
      <div class=" w-full max-w-screen-2xl mx-auto  px-8 flex justify-between items-center py-3 ">
        <div class=" flex items-center space-x-20 w-32">
          <NuxtLink to="/" aria-label="Go to Voix Homepage" @click="closeMenu">
            <VoixLogo class="w-24" mode="both" direction="horizontal" animate-on-hover />
          </NuxtLink>
        </div>
        <div class="-translate-y-1 flex items-center space-x-8  w-32">
          <button class="flex items-center space-x-2" aria-label="Open Menu" @click="showMenu = true">
            <Icon name="heroicons:bars-3-20-solid" class="w-6 h-6" />
            <span class="text-base">Menu</span>
          </button>
        </div>
      </div>
    </div>

    <!-- SubMenu -->
    <div
      class="fixed z-50 inset-0 bg-neutral-950 ease-out transform-gpu px-8 lg:px-40 duration-500 transition-all delay-400"
      :class="{
        'opacity-0 pointer-events-none ': !showMenu,
        'opacity-100': showMenu,
      }"
    >
      <button class="absolute z-50 top-0 right-0 m-12 lg:m-24" aria-label="Close Menu" @click="closeMenu">
        <Icon name="heroicons:x-circle" class="w-8 h-8 lg:w-12 lg:h-12" />
      </button>

      <div class="absolute z-0 bottom-0 right-0 opacity-10">
        <IllustrationsThreeJSCircles
          v-if="showMenu"
          class="w-[2000px] h-[500px] " :amplitude-min="0" :amplitude-max="2"
          :turbulence="0.1" :camera="{ x: 0, y: 2, z: -2 }"
        />
      </div>

      <div
        class="relative z-60 py-12 lg:py-24 space-y-12 duration-500" :class="{
          'opacity-0 -translate-x-24': !showMenu,
          'opacity-100 translate-x-0': showMenu,
        }"
      >
        <div class="flex flex-col space-y-20">
          <NuxtLink to="/" aria-label="Go to Voix Homepage" @click="closeMenu">
            <VoixLogo class="w-48" mode="both" direction="horizontal" animate-on-hover />
          </NuxtLink>
          <ul class="flex flex-col space-y-8 text-3xl lg:text-4xl">
            <li v-for="menuItem in menuItems" :key="menuItem.to">
              <button
                v-if="menuItem.children && (!activeMenuItem || activeMenuItem.title !== menuItem.title)"
                :aria-label="`Expand submenu for ${menuItem.title}`"
                @click="activateSubmenu(menuItem)"
              >
                <Icon name="heroicons:plus-20-solid" class="w-8 h-8" />
                <span v-if="!menuItem.to">
                  {{ menuItem.title }}
                </span>
              </button>
              <button v-else-if="menuItem.children" :aria-label="`Minimize submenu for ${menuItem.title}`" @click="requestDeactivateSubmenu">
                <Icon name="heroicons:minus-20-solid" class="w-8 h-8" />
                <span v-if="!menuItem.to">
                  {{ menuItem.title }}
                </span>
              </button>
              <div v-else class="inline-block w-8 h-8" />

              <NuxtLink v-if="menuItem.to" :to="menuItem.to" @click="closeMenu">
                {{ menuItem.title }}
              </NuxtLink>

              <div
                v-if="activeMenuItem && activeMenuItem.title === menuItem.title"
                class="relative lg:grid lg:grid-cols-2 pt-6 mt-3 pb-8 lg:border-t border-zinc-800"
              >
                <div>
                  <div
                    v-for="child in activeMenuItem.children"
                    :key="child.title"
                    class="flex pl-8"
                  >
                    <NuxtLink
                      class="relative flex items-center space-x-4 py-3 text-zinc-400 hover:text-white "
                      :to="child.to"
                      :aria-label="`Go to ${child.title}`"
                      @click="closeMenu"
                      @mouseenter="currentSection = child"
                      @mouseleave="currentSection = null"
                    >
                      <Icon v-if="child.icon" :name="child.icon" class="w-6 h-6 icon flex-none" />
                      <div v-else class="w-8 h-8" />
                      <div>
                        <div class="text-base font-sans">
                          {{ child.title }}
                        </div>
                      </div>
                    </NuxtLink>
                  </div>
                </div>

                <div class="hidden lg:block">
                  <div class="heading-3 pt-3">
                    <span class="subtitle !opacity-100">
                      <span class="opacity-50 text-zinc-400">About this section</span>
                    </span>
                  </div>

                  <div class="border-t border-zinc-700 pt-4 !text-zinc-400 font-lbm-sans text-lg font-light">
                    <div class="border-l-4 border-l-voix-stroke pl-4 italic">
                      <Transition name="fade-fast" mode="out-in">
                        <span :key="currentSection?.to">{{ currentAbout }}</span>
                      </Transition>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <InterfaceButtons reveal-color="bg-voix-middle" class="border border-zinc-400/50" to="/sales" size="large">
          Click to book a demo
          <template #revealText>
            It sure won't be boring
          </template>
        </InterfaceButtons>
      </div>
    </div>
  </div>
</template>

<style>
.menu-enter-active,
.menu-leave-active {
  transition: all 0.2s;
}

.menu-enter-from,
.menu-leave-to {
  opacity: 0;
  transform: scale(0.98);
  filter: blur(1rem);
}

.submenu-rect {
  @apply stroke-zinc-400/40;
  stroke-width: 3px;
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.fade-leave-active {
  transition: opacity 0.2s ease-out;
  transition-delay: 1s;
}

.fade-enter-active {
  transition: opacity 0.5s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-fast-leave-active {
  transition: opacity 0.2s ease-out;
}

.fade-fast-enter-active {
  transition: opacity 0.2s ease-in;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
  opacity: 0;
}
</style>
